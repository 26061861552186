import {
  Formik,
  FormikConfig,
  FormikHelpers,
  FormikProps,
  isFunction,
} from "formik";
import { FormikValues } from "formik/dist/types";
import { useHandleApiErrorField } from "@/hooks/handleApiErrorField";
import * as React from "react";
import { HTMLProps } from "react";

export type FormProps<Values> = Omit<
  HTMLProps<HTMLFormElement>,
  "onSubmit" | "onBlur" | "onChange" | "onAbort" | "children"
> & {
  initialValues: FormikConfig<Values>["initialValues"];
  onSubmit: FormikConfig<Values>["onSubmit"];
  children?:
    | ((props: Omit<FormikProps<Values>, "handleSubmit">) => React.ReactNode)
    | React.ReactNode;
  formikProps?: Omit<
    FormikConfig<Values>,
    "initialValues" | "onSubmit" | "children"
  >;
};

function Form<Values extends FormikValues = FormikValues, ExtraProps = {}>({
  initialValues,
  onSubmit,
  formikProps,
  children,
  ...formProps
}: FormProps<Values> & ExtraProps) {
  const handleApiErrorField = useHandleApiErrorField<Values>();

  const handleSubmit = async (
    values: Values,
    formikHelpers: FormikHelpers<Values>
  ) => {
    formikHelpers.setStatus(undefined);
    await handleApiErrorField(
      formikHelpers.setErrors,
      async () => {
        await onSubmit(values, formikHelpers);
      },
      formikHelpers.setStatus
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      {...formikProps}>
      {({ handleSubmit, ...props }) => (
        <form {...formProps} onSubmit={handleSubmit}>
          {children && isFunction(children) ? children(props) : children}
        </form>
      )}
    </Formik>
  );
}

export default Form;
