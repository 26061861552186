import React from "react";
import { withParams, WithParamsProps } from "@/hooks/params";
import { enqueueSnackbar } from "notistack";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "@reduxjs/toolkit";
import Page from "@/components/page";
import PageHeader from "@/components/pageHeader";
import PageContent from "@/components/pageContent";
import { Box, Button, Container, Grid } from "@mui/material";
import Form from "@/components/form";
import ApplicationForm from "@/forms/application";
import { ApplicationFormData, ApplicationObject } from "@/types";
import ApplicationService from "@/services/application";
import NavigationService from "@/services/navigation";
import { generatePath } from "react-router";
import { fetchApplicationsAction } from "@/store/reducers/application";
import Loading from "@/components/loading";
import Page404 from "@/components/page404";
import { Link } from "react-router-dom";

export type ApplicationEditPageProps = WithParamsProps<{
  applicationId: string;
}> &
  WithTranslation & {
    fetchApplications: typeof fetchApplicationsAction;
  };

type State = {
  loading: boolean;
  loadingPercent: number;
  application?: ApplicationObject;
};

class ApplicationEditPage extends React.PureComponent<
  ApplicationEditPageProps,
  State
> {
  constructor(props: ApplicationEditPageProps) {
    super(props);

    this.state = {
      loading: false,
      loadingPercent: 0,
      application: undefined,
    };
  }

  componentDidMount() {
    this.fetchApplication();
  }

  fetchApplication = () => {
    const { t, applicationId } = this.props;
    if (applicationId) {
      this.setState(
        {
          loading: true,
        },
        async () => {
          try {
            const response = await ApplicationService.retrieve(applicationId);
            this.setState({ application: response });
          } catch (e) {
            enqueueSnackbar(t("application.edit_page.error"), {
              variant: "error",
            });
          } finally {
            this.setState({ loading: false });
          }
        }
      );
    }
  };

  onSubmit = async (data: ApplicationFormData) => {
    const { t, fetchApplications, applicationId } = this.props;

    if (!applicationId) {
      return;
    }

    const response = await ApplicationService.update(applicationId, data);

    fetchApplications();

    enqueueSnackbar(t("application.edit_page.success"), {
      variant: "success",
    });

    NavigationService.navigate(
      generatePath("/dashboard/application/:id/", {
        id: response.id,
      })
    );
  };

  render() {
    const { t } = this.props;
    const { application, loading, loadingPercent } = this.state;

    if (loading) {
      return <Loading percent={loadingPercent} />;
    }

    if (!application) {
      return <Page404 />;
    }

    const applicationFormData: ApplicationFormData = application;

    return (
      <Page>
        <PageHeader>{t("dashboard.application.edit.title")}</PageHeader>
        <PageContent>
          <Container maxWidth="xl">
            <Form initialValues={applicationFormData} onSubmit={this.onSubmit}>
              {(formProps) => (
                <Grid container spacing={2}>
                  <ApplicationForm {...formProps} />
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}>
                      <Button component={Link} to="../">
                        {t("button.back")}
                      </Button>
                      <Button variant="contained" type="submit">
                        {t("button.edit")}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Form>
          </Container>
        </PageContent>
      </Page>
    );
  }
}

const _mapStateToProps = () => ({});

const _mapActionsToProps = {
  fetchApplications: fetchApplicationsAction,
};

const _connect = connect(_mapStateToProps, _mapActionsToProps);

export default compose<any>(
  _connect,
  withTranslation(),
  withParams
)(ApplicationEditPage);
