import BaseApiService from "@/services/baseApi";
import { AuthLoginResponse, LoginData } from "@/types";

class Auth {
  static instance: Auth;
  private readonly api!: typeof BaseApiService;

  constructor() {
    if (!Auth.instance) {
      this.api = BaseApiService;
      Auth.instance = this;
    }

    return Auth.instance;
  }

  public login = async (data: Omit<LoginData, "remember_me">) => {
    return await this.api.post<
      AuthLoginResponse,
      Omit<LoginData, "remember_me">
    >("/auth/login/", data);
  };
}

const AuthService = new Auth();
export default AuthService;
