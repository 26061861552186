import { RouteObject } from "react-router";
import AccountPage from "@/pages/dashboard/account";

const accountRoutes: RouteObject[] = [
  {
    id: "account",
    path: "",
    element: <AccountPage />,
  },
];

export default accountRoutes;
