import BaseApiService from "@/services/baseApi";
import { OAuth2AuthorizeData } from "@/types";

class OAuth2 {
  static instance: OAuth2;
  private readonly api!: typeof BaseApiService;
  readonly baseUrl = process.env.REACT_APP_OAUTH_URL ?? "";

  constructor() {
    if (!OAuth2.instance) {
      this.api = BaseApiService;
      OAuth2.instance = this;
    }

    return OAuth2.instance;
  }

  getAuthorizeData = async (params?: Record<any, any>) => {
    const _endpoint = "/authorize";

    return this.api.get<OAuth2AuthorizeData>(
      _endpoint,
      {
        get: params,
      },
      {
        baseURL: this.baseUrl,
      }
    );
  };

  authorize = async (data: Record<any, any>) => {
    return this.api.post<any, Record<string, string>>(
      "/authorize/",
      data,
      false,
      undefined,
      {
        baseURL: this.baseUrl,
      }
    );
  };
}

const OAuth2Service = new OAuth2();
export default OAuth2Service;
