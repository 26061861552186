import { RouteObject } from "react-router";
import LoginPage from "@/pages/auth/login";

const authRoutes: RouteObject = {
  path: "/",
  children: [
    {
      path: "/",
      element: <LoginPage />,
    },
  ],
};

export default authRoutes;
