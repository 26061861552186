export default function getFullRouteUrl(baseUrl: string, itemUrl: string) {
  let url = baseUrl;

  if (baseUrl.endsWith("/") && itemUrl.startsWith("/")) {
    url += itemUrl.substring(1);
  } else if (!baseUrl.endsWith("/") && !itemUrl.startsWith("/")) {
    url += "/" + itemUrl;
  } else {
    url += itemUrl;
  }

  if (!url.endsWith("/")) {
    url += "/";
  }

  return url;
}
