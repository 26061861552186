import React from "react";
import { MenuGroupObject, MenuObject } from "@/types";
// import { useAppSelector } from "@/hooks/appSelector"; TODO: Implement permissions
import { Box, List, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import NavigationItem from "@/components/navigationItem";

export type NavigationGroupProps = {
  group: MenuGroupObject;
  autoTranslateLabels?: boolean;
  onClickItem?: (item: MenuObject) => void;
};

const NavigationGroup: React.FC<NavigationGroupProps> = ({
  group,
  autoTranslateLabels,
  onClickItem,
}) => {
  // const { user: loggedUser } = useAppSelector((state) => state.auth); TODO: Implement permissions
  const { t } = useTranslation();

  const navItems = group.children
    ? group.children.map((item) => {
        return (
          <NavigationItem
            item={item}
            key={item.id}
            baseUrl={group.baseUrl}
            autoTranslateLabels={autoTranslateLabels}
            onClick={onClickItem}
          />
        );
      })
    : undefined;

  return (
    <List
      subheader={
        group.label && (
          <Box sx={{ pl: 3, mb: 1.5 }}>
            <Typography variant="subtitle2" color="text.secondary">
              {autoTranslateLabels ? t(group.label) : group.label}
            </Typography>
          </Box>
        )
      }>
      {navItems}
    </List>
  );
};

export default NavigationGroup;
