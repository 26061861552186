import React from "react";
import {
  AppBar,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { setToolbarTitle } from "@/store/reducers/dashboard";

export type PageHeaderProps = {
  children?: string | null;
};

const PageHeader: React.FC<PageHeaderProps> = ({ children }) => {
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setToolbarTitle(children || undefined));
  }, [children, dispatch]);

  return mdDown ? (
    <></>
  ) : (
    <>
      <AppBar position="relative">
        <Toolbar variant="dense">
          <Typography variant="h6">{children}</Typography>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default PageHeader;
