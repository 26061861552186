import { useTranslation } from "react-i18next";
import { FormikHelpers } from "formik";
import { AxiosError } from "axios";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import React from "react";

export interface WithHandleApiErrorField<Values = any> {
  handleApiErrorField: (
    setErrors: FormikHelpers<Values>["setErrors"],
    request: () => Promise<void>,
    setSubmitError?: (error: string) => void
  ) => Promise<void>;
}

export function useHandleApiErrorField<
  Values,
>(): WithHandleApiErrorField<Values>["handleApiErrorField"] {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return async (
    setErrors: FormikHelpers<Values>["setErrors"],
    request: () => Promise<void>,
    setSubmitError?: (error: string) => void
  ) => {
    try {
      await request();
    } catch (e: any) {
      const { response } = e as AxiosError<any>;
      if (response && response.status === 400) {
        const errors: Partial<any> = {};

        if (response.data.code) {
          let requireLogin = false;

          switch (response.data.code) {
            case "authentication_failed":
              requireLogin = true;
              break;
            default:
              break;
          }

          if (requireLogin) {
            dispatch({ type: "auth/logout" });
          }
        } else if (response.data.reason) {
          enqueueSnackbar(t(`error.${response.data.reason}`), {
            variant: "error",
          });
        }

        if (response.data.errors) {
          Object.keys(response.data.errors).forEach((key) => {
            errors[key] = response.data.errors[key];
          });

          if ("all_fields" in errors) {
            setSubmitError && setSubmitError(errors.all_fields);
            delete errors.all_fields;
          }

          setErrors(errors);
        }
      } else {
        setSubmitError && setSubmitError(t("error.unknown"));
      }

      throw e;
    }
  };
}

export function withHandleApiErrorField<Values>(
  Component: React.ComponentType<any>
) {
  return function (props: any) {
    const handleApiErrorField = useHandleApiErrorField<Values>();

    return <Component {...props} handleApiErrorField={handleApiErrorField} />;
  };
}
