import React from "react";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { CssBaseline, ThemeProvider } from "@mui/material";
import lightTheme from "@/theme/light";
import Routes from "@/routes";
import { SnackbarProvider } from "notistack";
import { ConfirmProvider } from "material-ui-confirm";
import { useTranslation } from "react-i18next";
import { useStore } from "react-redux";
import GlobalService from "@/services/global";

function App() {
  const { t } = useTranslation();
  GlobalService.store = useStore();

  return (
    <ThemeProvider theme={lightTheme}>
      <ConfirmProvider
        defaultOptions={{
          title: t("dialog.title"),
        }}>
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={2000}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}>
          <CssBaseline />
          <Routes />
        </SnackbarProvider>
      </ConfirmProvider>
    </ThemeProvider>
  );
}

export default App;
