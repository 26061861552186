import React from "react";
import { MenuGroupObject, MenuObject } from "@/types";
import NavigationGroup from "@/components/navigationGroup";
import { Box } from "@mui/material";

export type NavigationProps = {
  items: MenuGroupObject[];
  autoTranslateLabels?: boolean;
  onClickItem?: (item: MenuObject) => void;
};

const Navigation: React.FC<NavigationProps> = ({
  items,
  autoTranslateLabels,
  onClickItem,
}) => {
  const navGroups = items.map((group) => {
    return (
      <NavigationGroup
        group={group}
        key={group.id}
        autoTranslateLabels={autoTranslateLabels}
        onClickItem={onClickItem}
      />
    );
  });

  return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

Navigation.defaultProps = {
  autoTranslateLabels: true,
};

export default Navigation;
