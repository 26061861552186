import React from "react";
import { RootState } from "@/store";
import {
  AuthLogoutAction,
  AuthRetrieveAuthDataAction,
  logoutAction,
  retrieveAuthDataAction,
} from "@/store/reducers/auth";
import { connect } from "react-redux";
import { compose } from "@reduxjs/toolkit";
import { GlobalProps } from "@/types";
import AccountService from "@/services/account";
import withLocation, { WithLocationProps } from "@/hooks/location";
import { Navigate } from "react-router";

export type AuthenticationWrapperProps = GlobalProps &
  WithLocationProps & {
    retrieveAuthData: AuthRetrieveAuthDataAction;
    logout: AuthLogoutAction;
    hasToken: boolean;
    children?: React.ReactNode;
  };

type State = {
  redirect_to: string | null;
};

class AuthenticationWrapper extends React.PureComponent<
  AuthenticationWrapperProps,
  State
> {
  constructor(props: AuthenticationWrapperProps) {
    super(props);
    this.state = {
      redirect_to: null,
    };
  }

  componentDidMount() {
    this.retrieveUserData();
  }

  retrieveUserData = async () => {
    const { hasToken, retrieveAuthData, logout, location } = this.props;

    if (hasToken) {
      try {
        const response = await AccountService.getAccount();
        retrieveAuthData({
          user: response,
        });
        return;
      } catch (e: any) {
        logout();
      }
    } else {
      logout();
    }

    const next = encodeURIComponent(`${location.pathname}${location.search}`);

    this.setState({
      redirect_to: "/?next=" + next,
    });
  };

  render() {
    const { children } = this.props;
    const { redirect_to } = this.state;

    if (redirect_to) {
      return <Navigate to={redirect_to} replace={true} />;
    }

    return <>{children}</>;
  }
}

const _mapStateToProps = (state: RootState) => ({
  hasToken: state.auth.refreshToken !== null,
  loggedUser: state.auth.user,
  toolbarTitle: state.dashboard.toolbarTitle,
});

const _mapActionsToProps = {
  logout: logoutAction,
  retrieveAuthData: retrieveAuthDataAction,
};

const _connect = connect(_mapStateToProps, _mapActionsToProps);

export default compose<any>(_connect, withLocation)(AuthenticationWrapper);
