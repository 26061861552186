import React from "react";

export type MenuObject = {
  id: string;
  type: "page" | "dropdown" | "link";
  label?: string;
  icon?: React.ComponentType<any>;
  url?: string;
  children?: MenuObject[];
};

export interface BaseModelState<O> {
  data: O[];
  pages: number;
  currentPage: number;
}

export interface BaseModelAddPayload<O> {
  data: O[];
  pages?: number;
  currentPage?: number;
}

export interface BaseModelUpdatePayload<O> {
  data: O;
}

export interface BaseModelDeletePayload<
  O extends {
    id: string;
  },
> {
  id: O["id"];
}

export type BaseModelAddItemsAction<O> = (
  payload: BaseModelAddPayload<O>
) => void;

export type BaseModelUpdateItemAction<O> = (
  payload: BaseModelUpdatePayload<O>
) => void;

export type BaseModelDeleteItemAction<
  O extends {
    id: string;
  },
> = (payload: BaseModelDeletePayload<O>) => void;

export type BaseModelsReducers<
  O extends {
    id: string;
  },
> = {
  addItems: BaseModelAddItemsAction<O>;
  updateItem: BaseModelUpdateItemAction<O>;
  deleteItem: BaseModelDeleteItemAction<O>;
};

export type WithBaseModelAddItemsProps<O> = {
  addItems: BaseModelAddItemsAction<O>;
};

export type WithBaseModelUpdateItemProps<O> = {
  updateItem: BaseModelUpdateItemAction<O>;
};

export type WithBaseModelDeleteItemProps<
  O extends {
    id: string;
  },
> = {
  deleteItem: BaseModelDeleteItemAction<O>;
};

export type WithBaseModelProps<
  O extends {
    id: string;
  },
> = WithBaseModelAddItemsProps<O> &
  WithBaseModelUpdateItemProps<O> &
  WithBaseModelDeleteItemProps<O>;

export type MenuGroupObject = {
  id: string;
  label?: string;
  baseUrl?: string;
  children: MenuObject[];
};

export type ApiUrlParams = {
  path?: {
    [key: string]: any;
  };
  get?: {
    [key: string]: any;
  };
};

export type UserObject = {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  is_active: boolean;
};

export type LoginData = {
  email: string;
  password: string;
  remember_me: boolean;
};

export type AuthLoginResponse = {
  access: string;
  refresh: string;
};

export type AuthUserObject = {
  picture?: string;
  first_name: string;
  last_name: string;
  email: string;
};

export type GlobalProps = {
  loggedUser?: AuthUserObject;
};

export const grantTypes = [
  "authorization-code",
  "implicit",
  "password",
  "client-credentials",
  "openid-hybrid",
] as const;

export type GrantType = (typeof grantTypes)[number];

export const clientTypes = ["confidential", "public"] as const;

export type ClientType = (typeof clientTypes)[number];

export const algorithmsTypes = ["", "HS256", "RS256"] as const;

export type AlgorithmType = (typeof algorithmsTypes)[number];

export type ApplicationObject = {
  id: string;
  name: string;
  logo?: string;
  redirect_uris: string;
  authorization_grant_type: GrantType;
  client_type: ClientType;
  skip_authorization: boolean;
  created: string;
  updated: string;
  algorithm: AlgorithmType;
  client_id: string;
};

export type ApplicationWithSecretObject = ApplicationObject & {
  client_secret: string;
};

export type ApplicationFormData = {
  name: string;
  logo?: File | string;
  redirect_uris: string;
  authorization_grant_type: GrantType;
  client_type: ClientType;
  skip_authorization: boolean;
  algorithm: AlgorithmType;
};

export type OAuth2AuthorizeData = {
  application_name: string;
  client_id: string;
  redirect_uri: string;
  response_type: string;
  scopes: string[];
  scope_description: string;
  state: string;
  code_challenge?: string;
  code_challenge_method?: string;
  nonce?: string;
  claims?: string;
  redirect?: string;
};

export type Paginated<O> = {
  count: number;
  next?: string;
  pages: number;
  previous?: string;
  results: O[];
};

export type JwtCredentials = {
  access?: string;
  refresh?: string;
};

export type AccountChangePasswordData = {
  old_password: string;
  new_password: string;
  confirm_password: string;
};
