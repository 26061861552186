import { useLocation } from "react-router";
import React from "react";

export type WithLocationProps = {
  location: ReturnType<typeof useLocation>;
};

export default function withLocation(
  WrappedComponent: React.ComponentType<any>
) {
  return function WithLocation(props: any) {
    const location = useLocation();
    return <WrappedComponent {...props} location={location} />;
  };
}
