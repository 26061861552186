import React from "react";
import { Box, Paper, PaperProps } from "@mui/material";

export type PageProps = {
  children?: React.ReactNode;
  fullHeight?: boolean;
};

const Page = React.forwardRef<HTMLDivElement, PageProps & PaperProps>(
  ({ children, fullHeight }: PageProps, ref) => {
    return (
      <Box
        ref={ref}
        sx={{
          width: "100%",
          flexGrow: 1,
          overflow: "auto",
        }}>
        <Paper
          sx={{
            width: "100%",
            minHeight: "100%",
            display: "flex",
            flexDirection: "column",
            ...(fullHeight && { height: "100%" }),
          }}
          elevation={0}>
          {children}
        </Paper>
      </Box>
    );
  }
);

export default Page;
