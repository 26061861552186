import React from "react";
import { useRoutes } from "react-router";
import dashboardRoutes from "@/routes/dashboard";
import BaseLayout from "@/layouts/base";
import authRoutes from "@/routes/auth";
import oauth2Routes from "@/routes/oauth2";

const Routes: React.FC = () => {
  return useRoutes([
    {
      path: "/",
      element: <BaseLayout />,
      children: [authRoutes, dashboardRoutes, oauth2Routes],
    },
  ]);
};

export default Routes;
