import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

const DataDisplay = styled(Box, {
  name: "DataDisplay",
})(({ theme }) => ({
  overflow: "auto",
  padding: theme.spacing(1),
  background:
    theme.palette.mode === "dark" ? "#101010" : theme.palette.grey["100"],
  color:
    theme.palette.mode === "dark"
      ? theme.palette.grey["300"]
      : theme.palette.grey["800"],
  border: "1px solid",
  borderColor:
    theme.palette.mode === "dark"
      ? theme.palette.grey["800"]
      : theme.palette.grey["300"],
  borderRadius: theme.spacing(0.3),
  fontSize: "0.875rem",
  fontWeight: "700",
}));

export default DataDisplay;
