import React from "react";
import Page from "@/components/page";
import PageHeader from "@/components/pageHeader";
import { connect } from "react-redux";
import { compose } from "@reduxjs/toolkit";
import { WithTranslation, withTranslation } from "react-i18next";
import PageContent from "@/components/pageContent";
import { withParams, WithParamsProps } from "@/hooks/params";
import { enqueueSnackbar } from "notistack";
import ApplicationService from "@/services/application";
import { ApplicationObject } from "@/types";
import Loading from "@/components/loading";
import {
  Box,
  Button,
  Container,
  Grid,
  Icon,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { MdOutlineDelete } from "react-icons/md";
import { MdOutlineEdit } from "react-icons/md";
import DataDisplay from "@/components/dataDisplay";
import { withConfirm, WithConfirmProps } from "@/hooks/confirm";
import NavigationService from "@/services/navigation";
import {
  ApplicationDeleteItemAction,
  deleteApplicationAction,
} from "@/store/reducers/application";
import Page404 from "@/components/page404";

export type ApplicationViewPageProps = WithTranslation &
  WithConfirmProps &
  WithParamsProps<{
    applicationId: string;
  }> & {
    deleteApplication: ApplicationDeleteItemAction;
  };

export type State = {
  loading: boolean;
  loadingPercent: number;
  application?: ApplicationObject;
};

class ApplicationViewPage extends React.PureComponent<
  ApplicationViewPageProps,
  State
> {
  constructor(props: ApplicationViewPageProps) {
    super(props);

    this.state = {
      loading: false,
      loadingPercent: 0,
      application: undefined,
    };
  }

  componentDidMount() {
    this.fetchApplication();
  }

  fetchApplication = () => {
    const { t, applicationId } = this.props;
    if (applicationId) {
      this.setState(
        {
          loading: true,
        },
        async () => {
          try {
            const response = await ApplicationService.retrieve(applicationId);
            this.setState({ application: response });
          } catch (e) {
            enqueueSnackbar(t("application.view_page.error"), {
              variant: "error",
            });
          } finally {
            this.setState({ loading: false });
          }
        }
      );
    }
  };

  onClickDelete = () => {
    const { confirm, t, applicationId, deleteApplication } = this.props;

    if (!applicationId) return;

    confirm({
      title: t("application.view_page.delete_confirm.title"),
      description: t("application.view_page.delete_confirm.description"),
    }).then(async () => {
      try {
        await ApplicationService.delete(applicationId);
        enqueueSnackbar(t("application.view_page.delete_success"), {
          variant: "success",
        });

        deleteApplication({
          id: applicationId,
        });

        NavigationService.navigate("/dashboard/application/");
      } catch (e) {
        enqueueSnackbar(t("application.view_page.delete_error"), {
          variant: "error",
        });
      }
    });
  };

  render() {
    const { t } = this.props;
    const { loading, loadingPercent, application } = this.state;

    if (loading) {
      return <Loading percent={loadingPercent} />;
    }

    if (!application) {
      return <Page404 />;
    }

    return (
      <Page>
        <PageHeader>
          {`${t("application.view_page.title")} - ${application.name}`}
        </PageHeader>
        <PageContent>
          <Container maxWidth="lg">
            <Link component={RouterLink} to="../" variant="body2">
              {t("application.view_page.back_to_list")}
            </Link>
            <Grid container rowSpacing={3} columnSpacing={2} px={1} mt={0.8}>
              <Grid item xs={12} md={6}>
                <Stack>
                  <Typography variant="caption" color="text.secondary">
                    {t("application.fields.name")}
                  </Typography>
                  <Typography variant="subtitle2" component="span">
                    {application.name}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack>
                  <Typography variant="caption" color="text.secondary">
                    {t("application.fields.redirect_uris")}
                  </Typography>
                  <Typography variant="subtitle2" component="span">
                    {application.redirect_uris}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack>
                  <Typography variant="caption" color="text.secondary">
                    {t("application.fields.authorization_grant_type")}
                  </Typography>
                  <Typography variant="subtitle2" component="span">
                    {t(
                      `application.grantTypes.${application.authorization_grant_type}`
                    )}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack>
                  <Typography variant="caption" color="text.secondary">
                    {t("application.fields.client_type")}
                  </Typography>
                  <Typography variant="subtitle2" component="span">
                    {t(`application.clientTypes.${application.client_type}`)}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack>
                  <Typography variant="caption" color="text.secondary">
                    {t("application.fields.algorithm")}
                  </Typography>
                  <Typography variant="subtitle2" component="span">
                    {t(
                      `application.algorithmsTypes.${
                        application.algorithm === ""
                          ? "NO_ALGORITHM"
                          : application.algorithm
                      }`
                    )}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack>
                  <Typography variant="caption" color="text.secondary">
                    {t("application.fields.skip_authorization")}
                  </Typography>
                  <Typography variant="subtitle2" component="span">
                    {t(
                      `commons.${application.skip_authorization ? "yes" : "no"}`
                    )}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack>
                  <Typography variant="caption" color="text.secondary">
                    {t("application.fields.client_id")}
                  </Typography>
                  <DataDisplay>{application.client_id}</DataDisplay>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}>
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                      textAlign: "right",
                    }}>
                    <Button
                      variant="contained"
                      color="error"
                      size="small"
                      onClick={this.onClickDelete}
                      startIcon={<Icon component={MdOutlineDelete} />}>
                      {t("button.delete")}
                    </Button>
                    <Button
                      component={RouterLink}
                      to="edit/"
                      size="small"
                      variant="contained"
                      startIcon={<Icon component={MdOutlineEdit} />}>
                      {t("button.edit")}
                    </Button>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </PageContent>
      </Page>
    );
  }
}

const _mapStateToProps = () => ({});

const _mapActionsToProps = {
  deleteApplication: deleteApplicationAction,
};

const _connect = connect(_mapStateToProps, _mapActionsToProps);

export default compose<any>(
  _connect,
  withTranslation(),
  withParams,
  withConfirm
)(ApplicationViewPage);
