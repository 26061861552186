import React from "react";
import { Outlet, useNavigate } from "react-router";
import NavigationService from "@/services/navigation";

function BaseLayout() {
  const navigate = useNavigate();

  NavigationService.setNavigate(navigate);
  return (
    <>
      <Outlet />
    </>
  );
}

export default BaseLayout;
