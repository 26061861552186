import React from "react";
import { RootState } from "@/store";
import {
  AuthLoginAction,
  AuthLogoutAction,
  AuthRetrieveAuthDataAction,
  loginAction,
  logoutAction,
  retrieveAuthDataAction,
} from "@/store/reducers/auth";
import { connect } from "react-redux";
import { compose } from "@reduxjs/toolkit";
import {
  Box,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { WithTranslation, withTranslation } from "react-i18next";
import { LoginData } from "@/types";
import {
  withHandleApiErrorField,
  WithHandleApiErrorField,
} from "@/hooks/handleApiErrorField";
import AuthService from "@/services/auth";
import Form from "@/components/form";
import NavigationService from "@/services/navigation";
import AccountService from "@/services/account";
import withLocation, { WithLocationProps } from "@/hooks/location";
import { FormikHelpers } from "formik";

export type LoginPageProps = {};

type Props = WithHandleApiErrorField &
  WithTranslation &
  LoginPageProps &
  WithLocationProps & {
    token: string;
    loginSubmit: AuthLoginAction;
    retrieveAuthData: AuthRetrieveAuthDataAction;
    hasToken: boolean;
    logout: AuthLogoutAction;
  };

class LoginPage extends React.PureComponent<Props> {
  componentDidMount() {
    this.retrieveUserData();
  }

  retrieveUserData = () => {
    const { hasToken, retrieveAuthData, logout } = this.props;

    if (hasToken) {
      (async () => {
        try {
          const response = await AccountService.getAccount();
          retrieveAuthData({
            user: response,
          });

          NavigationService.navigate("/dashboard");
        } catch (e) {
          logout();
        }
      })();
    } else {
      logout();
    }
  };

  onSubmit = async (
    data: LoginData,
    { setStatus }: FormikHelpers<LoginData>
  ) => {
    const { loginSubmit, location } = this.props;
    const params = new URLSearchParams(location.search);

    try {
      const response = await AuthService.login({
        email: data.email,
        password: data.password,
      });

      loginSubmit({
        accessToken: response.access,
        refreshToken: response.refresh,
        remember_me: data.remember_me,
      });

      if (params.has("next")) {
        NavigationService.navigate(params.get("next") || "/");
        return;
      }

      NavigationService.navigate("/dashboard");
    } catch (e: any) {
      const { response } = e;

      if (response && "reason" in response.data) {
        setStatus(response.data.reason);
      }
    }
  };

  render() {
    const { t } = this.props;

    const initialValues: LoginData = {
      email: "",
      password: "",
      remember_me: false,
    };

    return (
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Paper
          sx={{
            width: {
              xs: "90%",
              md: "500px",
            },
            padding: 2,
          }}>
          <Form initialValues={initialValues} onSubmit={this.onSubmit}>
            {({
              status,
              errors,
              touched,
              values,
              handleBlur,
              handleChange,
              isSubmitting,
            }) => (
              <Grid container spacing={1.5}>
                <Grid item xs={12}>
                  <Typography variant="h6">lbdev - Odin</Typography>
                  <Typography variant="caption">
                    {t("auth-login-label.login")}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="email"
                        name="email"
                        variant="standard"
                        label={t("auth-login-label.email")}
                        disabled={isSubmitting}
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="password"
                        name="password"
                        variant="standard"
                        type="password"
                        label={t("auth-login-label.password")}
                        disabled={isSubmitting}
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}>
                    <FormControlLabel
                      control={<Switch />}
                      disabled={isSubmitting}
                      checked={values.remember_me}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="remember_me"
                      label={t("auth-login-label.remember_me")}
                    />
                    <LoadingButton
                      loading={isSubmitting}
                      type="submit"
                      variant="contained">
                      {t("auth-login-label.login")}
                    </LoadingButton>
                  </Box>
                </Grid>

                {status && (
                  <Grid item xs={12}>
                    <Typography color="error">{status}</Typography>
                  </Grid>
                )}
              </Grid>
            )}
          </Form>
        </Paper>
      </Box>
    );
  }
}

const _mapStateToProps = (state: RootState) => ({
  hasToken: state.auth.refreshToken !== null,
});

const _mapActionsToProps = {
  loginSubmit: loginAction,
  retrieveAuthData: retrieveAuthDataAction,
  logout: logoutAction,
};

const _connect = connect(_mapStateToProps, _mapActionsToProps);

export default compose<any>(
  _connect,
  withTranslation(),
  withHandleApiErrorField,
  withLocation
)(LoginPage);
