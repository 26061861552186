import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  RawAxiosRequestConfig,
} from "axios";
import { ApiUrlParams, JwtCredentials, Paginated } from "@/types";
import encodePathParams from "@/utils/encodePathParams";
import _ from "lodash";
import jwt_decode from "jwt-decode";
import GlobalService from "@/services/global";
import TokenService from "@/services/token";

class BaseApi {
  static instance: BaseApi;
  public axios!: AxiosInstance;
  private readonly baseUrl!: string;
  private jwtExp: number | null = null;

  private isRefreshing = false;

  constructor() {
    if (!BaseApi.instance) {
      this.baseUrl = process.env.REACT_APP_API_URL ?? "";
      this.axios = axios.create({
        baseURL: this.baseUrl,
      });

      this.axios.interceptors.response.use((resp) => resp, this.refreshToken2);

      BaseApi.instance = this;
    }

    return BaseApi.instance;
  }

  isAccessTokenExpired = (accessToken: string) => {
    const decoded = jwt_decode<any>(accessToken);
    this.jwtExp = decoded.exp;
    return decoded.exp * 1000 < Date.now();
  };

  refreshJwtToken = (value: AxiosRequestConfig<any>) => {
    const credentials = TokenService.getJwtCredentials();
    const remember = GlobalService.store.getState().auth.rememberMe;
    if (!this.isRefreshing && credentials && credentials.refresh) {
      if (
        this.isAccessTokenExpired(credentials.access) ||
        !credentials.access
      ) {
        this.isRefreshing = true;
        this.post<Omit<JwtCredentials, "refresh">>(
          "auth/login/refresh/",
          {
            refresh: credentials.refresh,
          },
          false
        )
          .then((response) => {
            if (value.headers) {
              value.headers.Authorization = `Bearer ${response.access}`;
              TokenService.setJwtCredentials(
                {
                  refresh: credentials.refresh,
                  access: response.access,
                },
                remember
              );
            }
          })
          .finally(() => {
            this.isRefreshing = false;
          });
      }
    }

    return value;
  };

  refreshToken2 = async (err: any) => {
    const originalConfig = err.config;

    if (err.response.status === 401 && !originalConfig._retry) {
      originalConfig._retry = true;

      const credentials = TokenService.getJwtCredentials();

      const remember = GlobalService.store.getState().auth.rememberMe;
      if (credentials && credentials.refresh) {
        const response = await this.post<Omit<JwtCredentials, "refresh">>(
          "auth/login/refresh/",
          {
            refresh: credentials.refresh,
          },
          false
        );
        TokenService.setJwtCredentials(
          {
            refresh: credentials.refresh,
            access: response.access,
          },
          remember
        );

        originalConfig.headers.Authorization = `Bearer ${response.access}`;
        return this.axios(originalConfig);
      }
    }

    return Promise.reject(err);
  };

  private getHeaders = (
    headers?: RawAxiosRequestConfig<any>["headers"]
  ): RawAxiosRequestConfig<any>["headers"] => {
    const credentials = TokenService.getJwtCredentials();

    return _.merge(headers, {
      Authorization:
        credentials && credentials.access
          ? `Bearer ${credentials.access}`
          : undefined,
    });
  };

  convertValuesToFormData = (formData: FormData, key: any, data: any) => {
    if (data === null || data === undefined) {
      formData.append(key, "");
    } else if (Array.isArray(data)) {
      data.forEach((v) => {
        this.convertValuesToFormData(formData, key, v);
      });
    } else if (typeof data === "object") {
      Object.keys(data).forEach((k) => {
        this.convertValuesToFormData(formData, `${key}[${k}]`, data[k]);
      });
    } else if (data instanceof File) {
      formData.append(key, data, data.name);
    } else if (data instanceof Date) {
      formData.append(key, data.toISOString());
    } else {
      formData.append(key, data.toString());
    }
  };

  public generateFormData<
    T extends {
      [key: string]: any;
    },
  >(data: T) {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      const value = data[key];
      this.convertValuesToFormData(formData, key, value);
    });

    return formData;
  }

  public get = async <T>(
    endpoint: string,
    params?: ApiUrlParams,
    { headers, ...config }: RawAxiosRequestConfig = {}
  ): Promise<T> => {
    let _endpoint = endpoint;

    if (params) {
      _endpoint = encodePathParams(_endpoint, params.path);

      if (params.get) {
        Object.keys(params.get).forEach((key) => {
          if (params.get![key] === null || params.get![key] === undefined) {
            delete params.get![key];
          }
        });

        _endpoint += `?${new URLSearchParams(params.get).toString()}`;
      }
    }

    const response = await this.axios.get<T>(_endpoint, {
      ...config,
      headers: this.getHeaders(headers),
    });
    return response.data;
  };

  public post = async <D, FD extends object = {}>(
    endpoint: string,
    data: FD,
    asFormData = false,
    params?: ApiUrlParams["path"],
    { headers, ...config }: RawAxiosRequestConfig = {}
  ): Promise<D> => {
    let _endpoint = encodePathParams(endpoint, params);

    const response = await this.axios.post<D>(
      _endpoint,
      asFormData ? this.generateFormData(data) : data,
      {
        ...config,
        headers: this.getHeaders(headers),
      }
    );
    return response.data;
  };

  put = async <D, FD extends object>(
    endpoint: string,
    data: FD,
    asFormData = false,
    params?: ApiUrlParams["path"],
    { headers, ...config }: RawAxiosRequestConfig = {}
  ): Promise<D> => {
    let _endpoint = encodePathParams(endpoint, params);

    const response = await this.axios.put<D>(
      _endpoint,
      asFormData ? this.generateFormData(data) : data,
      {
        ...config,
        headers: this.getHeaders(headers),
      }
    );
    return response.data;
  };

  patch = async <D, FD extends object>(
    endpoint: string,
    data: FD,
    asFormData = false,
    params?: ApiUrlParams["path"],
    { headers, ...config }: RawAxiosRequestConfig = {}
  ): Promise<D> => {
    let _endpoint = encodePathParams(endpoint, params);

    const response = await this.axios.patch<D>(
      _endpoint,
      asFormData ? this.generateFormData(data) : data,
      {
        ...config,
        headers: this.getHeaders(headers),
      }
    );
    return response.data;
  };

  delete = async <D>(
    endpoint: string,
    params?: ApiUrlParams["path"],
    { headers, ...config }: RawAxiosRequestConfig = {}
  ): Promise<D> => {
    let _endpoint = encodePathParams(endpoint, params);

    const response = await this.axios.delete<D>(_endpoint, {
      ...config,
      headers: this.getHeaders(headers),
    });
    return response.data;
  };

  public retrieve = async <D>(
    endpoint: string,
    id: string,
    params?: ApiUrlParams["path"],
    config?: RawAxiosRequestConfig
  ): Promise<D> => {
    const _params = _.merge(params, { id });

    return this.get<D>(endpoint, { path: _params }, config);
  };

  public listPaginated = async <D>(
    endpoint: string,
    page?: number,
    params?: ApiUrlParams,
    config?: RawAxiosRequestConfig
  ) => {
    const _params = _.merge(params, { get: { page } });

    return this.get<Paginated<D>>(endpoint, _params, config);
  };

  // Aliases

  public list = this.listPaginated;

  public create = this.post;

  public update = this.put;
}

const BaseApiService = new BaseApi();
export default BaseApiService;
