export default function encodePathParams(
  url: string,
  params?: Record<string, any>
) {
  if (!params) return url;

  let _url = url;

  Object.keys(params).forEach((key) => {
    _url = _url.replaceAll(`:${key}`, params[key]);
  });

  return _url;
}
