import React from "react";
import { Box } from "@mui/material";

export type PageContentProps = {
  disablePadding?: boolean;
  children?: React.ReactNode;
};

const PageContent: React.FC<PageContentProps> = ({
  disablePadding,
  children,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        overflow: "auto",
        p: disablePadding ? 0 : 1.5,
      }}>
      {children}
    </Box>
  );
};

export default PageContent;
