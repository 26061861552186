import React from "react";
import { ConfirmOptions, useConfirm } from "material-ui-confirm";

export type WithConfirmProps = {
  confirm: (options?: ConfirmOptions) => Promise<void>;
};

export const withConfirm = (WrappedComponent: React.ComponentType<any>) => {
  return (props: any) => {
    const confirm = useConfirm();

    return <WrappedComponent {...props} confirm={confirm} />;
  };
};
