import React from "react";
import { connect } from "react-redux";
import { compose } from "@reduxjs/toolkit";
import {
  AccountChangePasswordData,
  AuthUserObject,
  GlobalProps,
} from "@/types";
import { RootState } from "@/store";
import Page from "@/components/page";
import PageHeader from "@/components/pageHeader";
import { withTranslation, WithTranslation } from "react-i18next";
import PageContent from "@/components/pageContent";
import {
  fetchAccountAction,
  retrieveAuthDataAction,
} from "@/store/reducers/auth";
import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Loading from "@/components/loading";
import { enqueueSnackbar } from "notistack";
import AccountService from "@/services/account";
import Form from "@/components/form";

export type AccountPageProps = GlobalProps &
  WithTranslation & {
    fetchAccount: typeof fetchAccountAction;
    retrieveAuthData: typeof retrieveAuthDataAction;
  };

class AccountPage extends React.PureComponent<AccountPageProps> {
  componentDidMount() {
    this.props.fetchAccount();
  }

  onSubmitAccountInfo = async (data: Partial<AuthUserObject>) => {
    const { t, retrieveAuthData } = this.props;
    try {
      const response = await AccountService.partialUpdateAccount({
        first_name: data.first_name,
        last_name: data.last_name,
      });

      retrieveAuthData({
        user: response,
      });

      enqueueSnackbar(t("account.info.success"), {
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar(t("account.messages.account_info_update_error"), {
        variant: "error",
      });
    }
  };

  onSubmitPasswordChange = async (data: AccountChangePasswordData) => {
    const { t } = this.props;
    await AccountService.changePassword(data);

    enqueueSnackbar(t("account.change_password.success"), {
      variant: "success",
    });
  };

  renderAccountInfo() {
    const { t, loggedUser } = this.props;

    return (
      <Form initialValues={loggedUser!} onSubmit={this.onSubmitAccountInfo}>
        {({ values, touched, errors, handleChange, handleBlur }) => (
          <Grid container rowSpacing={2}>
            <Grid item xs={12} md={4} lg={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}>
                    <Avatar
                      sx={{
                        width: 96,
                        height: 96,
                        mt: 3,
                      }}
                      src={loggedUser!.picture}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={8} lg={10}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    id="first_name"
                    name="first_name"
                    variant="standard"
                    value={values.first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors.first_name && !!touched.first_name}
                    helperText={touched.first_name && errors.first_name}
                    label={t("account.fields.first_name")}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    id="last_name"
                    name="last_name"
                    variant="standard"
                    value={values.last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors.last_name && !!touched.last_name}
                    helperText={touched.last_name && errors.last_name}
                    label={t("account.fields.last_name")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="email"
                    name="email"
                    variant="standard"
                    value={values.email}
                    disabled
                    label={t("account.fields.email")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      mt: 1,
                    }}>
                    <Button variant="contained" type="submit">
                      {t("button.save")}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Form>
    );
  }

  renderPasswordChange() {
    const { t } = this.props;

    const initialValues: AccountChangePasswordData = {
      old_password: "",
      new_password: "",
      confirm_password: "",
    };

    return (
      <Form
        initialValues={initialValues}
        onSubmit={this.onSubmitPasswordChange}>
        {({ values, touched, errors, handleChange, handleBlur }) => (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="old_password"
                name="old_password"
                variant="standard"
                type="password"
                value={values.old_password}
                error={!!errors.old_password && !!touched.old_password}
                helperText={touched.old_password && errors.old_password}
                onChange={handleChange}
                onBlur={handleBlur}
                label={t("account.fields.current_password")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id="new_password"
                name="new_password"
                variant="standard"
                type="password"
                value={values.new_password}
                error={!!errors.new_password && !!touched.new_password}
                helperText={touched.new_password && errors.new_password}
                onChange={handleChange}
                onBlur={handleBlur}
                label={t("account.fields.new_password")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id="confirm_password"
                name="confirm_password"
                variant="standard"
                type="password"
                value={values.confirm_password}
                error={!!errors.confirm_password && !!touched.confirm_password}
                helperText={touched.confirm_password && errors.confirm_password}
                onChange={handleChange}
                onBlur={handleBlur}
                label={t("account.fields.confirm_password")}
              />
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  mt: 1,
                }}>
                <Button variant="contained" type="submit">
                  {t("button.save")}
                </Button>
              </Box>
            </Grid>
          </Grid>
        )}
      </Form>
    );
  }

  render() {
    const { loggedUser } = this.props;
    const { t } = this.props;

    if (!loggedUser) {
      return <Loading />;
    }

    return (
      <Page>
        <PageHeader>{t("account.title")}</PageHeader>
        <PageContent>
          <Container maxWidth="xl">
            <Stack rowGap={3}>
              <Typography variant="h6">{t("account.info.title")}</Typography>
              {this.renderAccountInfo()}
              <Divider />
              <Typography variant="h6">
                {t("account.password_change.title")}
              </Typography>
              {this.renderPasswordChange()}
            </Stack>
          </Container>
        </PageContent>
      </Page>
    );
  }
}

const _mapStateToProps = (state: RootState) => ({
  loggedUser: state.auth.user,
});

const _mapDispatchToProps = {
  fetchAccount: fetchAccountAction,
  retrieveAuthData: retrieveAuthDataAction,
};

const _connect = connect(_mapStateToProps, _mapDispatchToProps);

export default compose<any>(_connect, withTranslation())(AccountPage);
