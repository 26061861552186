import BaseApiService from "@/services/baseApi";
import { AccountChangePasswordData, AuthUserObject } from "@/types";

class Account {
  static instance: Account;
  private readonly api!: typeof BaseApiService;

  constructor() {
    if (!Account.instance) {
      this.api = BaseApiService;
      Account.instance = this;
    }

    return Account.instance;
  }

  public getAccount = async () => {
    return await this.api.get<AuthUserObject>("/account/");
  };

  public updateAccount = async (data: Partial<AuthUserObject>) => {
    return await this.api.put<AuthUserObject, Partial<AuthUserObject>>(
      "/account/",
      data
    );
  };

  public partialUpdateAccount = async (data: Partial<AuthUserObject>) => {
    return await this.api.patch<AuthUserObject, Partial<AuthUserObject>>(
      "/account/",
      data
    );
  };

  public changePassword = async (data: AccountChangePasswordData) => {
    return await this.api.post<AuthUserObject, AccountChangePasswordData>(
      "/account/change_password/",
      data
    );
  };
}

const AccountService = new Account();
export default AccountService;
