import React from "react";
import Page from "@/components/page";
import PageHeader from "@/components/pageHeader";
import PageContent from "@/components/pageContent";
import { ApplicationObject } from "@/types";
import { RootState } from "@/store";
import { connect } from "react-redux";
import { compose } from "@reduxjs/toolkit";
import { fetchApplicationsAction } from "@/store/reducers/application";
import {
  Avatar,
  Box,
  Fab,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import Loading from "@/components/loading";
import { FiExternalLink } from "react-icons/fi";
import { WithTranslation, withTranslation } from "react-i18next";
import { MdAdd, MdOutlineEdit } from "react-icons/md";
import ScrollTriggerTransition from "@/components/scrollTriggerTransition";
import { Link } from "react-router-dom";
import { MdOutlineDelete } from "react-icons/md";
import { withConfirm, WithConfirmProps } from "@/hooks/confirm";
import ApplicationService from "@/services/application";
import { enqueueSnackbar } from "notistack";

export type ApplicationPageProps = WithTranslation &
  WithConfirmProps & {
    applications: ApplicationObject[];
    fetchApplications: typeof fetchApplicationsAction;
  };

type State = {
  loading: boolean;
  loadingPercent: number;
  contextMenu: {
    mouseX: number;
    mouseY: number;
    selected: ApplicationObject;
  } | null;
};

class ApplicationPage extends React.PureComponent<ApplicationPageProps, State> {
  pageRef = React.createRef<HTMLDivElement>();

  constructor(props: ApplicationPageProps) {
    super(props);

    this.state = {
      loading: false,
      loadingPercent: 0,
      contextMenu: null,
    };
  }

  componentDidMount() {
    this.fetchApplications();
  }

  fetchApplications = () => {
    const { fetchApplications } = this.props;

    this.setState({ loading: true, loadingPercent: 0 }, async () => {
      try {
        await fetchApplications(
          1,
          {},
          {
            onDownloadProgress: (progressEvent) => {},
          }
        );
      } catch (e: any) {
        console.log(e);
      } finally {
        this.setState({ loading: false, loadingPercent: 0 });
      }
    });
  };

  handleContextMenu =
    (application: ApplicationObject) => (event: React.MouseEvent) => {
      event.preventDefault();

      this.setState({
        contextMenu: {
          mouseX: event.clientX + 2,
          mouseY: event.clientY - 6,
          selected: application,
        },
      });
    };

  handleDelete = () => {
    const { confirm, t, fetchApplications } = this.props;
    const { contextMenu } = this.state;

    this.closeContextMenu();

    if (!contextMenu) return;

    const applicationId = contextMenu.selected.id;

    confirm({
      title: t("application.view_page.delete_confirm.title"),
      description: t("application.view_page.delete_confirm.description"),
    }).then(async () => {
      try {
        await ApplicationService.delete(applicationId);
        enqueueSnackbar(t("application.view_page.delete_success"), {
          variant: "success",
        });

        fetchApplications();
      } catch (e) {
        enqueueSnackbar(t("application.view_page.delete_error"), {
          variant: "error",
        });
      }
    });
  };

  closeContextMenu = () => this.setState({ contextMenu: null });

  render() {
    const { applications, t } = this.props;
    const { loading, loadingPercent, contextMenu } = this.state;

    return (
      <>
        <Page fullHeight={loading} ref={this.pageRef}>
          <PageHeader>{t("application.title")}</PageHeader>
          <PageContent disablePadding>
            {loading ? (
              <Loading percent={loadingPercent} />
            ) : (
              <List>
                {applications.map((app) => (
                  <ListItem
                    key={app.id}
                    disablePadding
                    secondaryAction={
                      <IconButton
                        edge="end"
                        title={t("application.access_app") || ""}>
                        <FiExternalLink />
                      </IconButton>
                    }>
                    <ListItemButton
                      component={Link}
                      to={`${app.id}/`}
                      onContextMenu={this.handleContextMenu(app)}>
                      <ListItemAvatar>
                        <Avatar alt={app.name} src={app.logo} />
                      </ListItemAvatar>
                      <ListItemText primary={app.name} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            )}
          </PageContent>
          <Menu
            open={contextMenu !== null}
            onClose={this.closeContextMenu}
            anchorReference="anchorPosition"
            anchorPosition={
              contextMenu
                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                : undefined
            }>
            <MenuItem
              component={Link}
              to={contextMenu ? `${contextMenu.selected.id}/edit/` : "#"}>
              <ListItemIcon>
                <Icon>
                  <MdOutlineEdit />
                </Icon>
              </ListItemIcon>
              <ListItemText>{t("button.edit")}</ListItemText>
            </MenuItem>
            <MenuItem onClick={this.handleDelete}>
              <ListItemIcon>
                <Icon>
                  <MdOutlineDelete />
                </Icon>
              </ListItemIcon>
              <ListItemText>{t("button.delete")}</ListItemText>
            </MenuItem>
          </Menu>
        </Page>
        <ScrollTriggerTransition
          threshold={100}
          invert
          target={this.pageRef.current || undefined}>
          <Box
            sx={{
              position: "fixed",
              bottom: 16,
              right: 16,
            }}>
            <Fab component={Link} to="create/" color="primary" size="medium">
              <Icon component={MdAdd} />
            </Fab>
          </Box>
        </ScrollTriggerTransition>
      </>
    );
  }
}

const _mapStateToProps = (state: RootState) => ({
  applications: state.Application.data,
});

const _mapDispatchToProps = {
  fetchApplications: fetchApplicationsAction,
};

const _connect = connect(_mapStateToProps, _mapDispatchToProps);

export default compose<any>(
  _connect,
  withTranslation(),
  withConfirm
)(ApplicationPage);
