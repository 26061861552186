import React from "react";
import { RootState } from "@/store";
import { logoutAction } from "@/store/reducers/auth";
import { connect } from "react-redux";
import { compose } from "@reduxjs/toolkit";
import { withTranslation } from "react-i18next";
import { GlobalProps } from "@/types";

export type HomePageProps = GlobalProps & {};

class HomePage extends React.PureComponent<HomePageProps> {
  render() {
    return <>HomePage</>;
  }
}

const _mapStateToProps = (state: RootState) => ({
  loggedUser: state.auth.user,
});

const _mapActionsToProps = {
  logout: logoutAction,
};

const _connect = connect(_mapStateToProps, _mapActionsToProps);

export default compose<any>(_connect, withTranslation())(HomePage);
