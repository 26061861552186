import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import Application from "@/store/reducers/application";
import auth from "@/store/reducers/auth";
import dashboard from "@/store/reducers/dashboard";

export const store = configureStore({
  reducer: {
    Application,
    auth,
    dashboard,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type StoreType = typeof store;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
