import AuthorizePage from "@/pages/oauth/authorize";
import { RouteObject } from "react-router";
import AuthenticationWrapper from "@/wrappers/authentication";

const oauth2Routes: RouteObject = {
  path: "/oauth2/",
  children: [
    {
      path: "authorize/",
      element: (
        <AuthenticationWrapper>
          <AuthorizePage />
        </AuthenticationWrapper>
      ),
    },
  ],
};

export default oauth2Routes;
