import {
  BaseModelAddPayload,
  BaseModelDeletePayload,
  BaseModelState,
  BaseModelUpdatePayload,
} from "@/types";
import { PayloadAction } from "@reduxjs/toolkit";

export function baseModelsReducers<
  O extends {
    id: any;
  },
  State extends BaseModelState<O>,
>() {
  return {
    addItems: (state: State, action: PayloadAction<BaseModelAddPayload<O>>) => {
      // action.payload.data.forEach((value) => {
      //   // Check if the item is already in the array
      //   let index = state.data.findIndex((item) => item.id === value.id);
      //
      //   // If the item is in the array, update it
      //   if (index !== -1) {
      //     state.data[index] = value;
      //   } else {
      //     // If the item is not in the array, add it
      //     state.data.push(value);
      //   }
      // });
      state.data = action.payload.data;

      if (action.payload.currentPage) {
        state.currentPage = action.payload.currentPage;
      }

      if (action.payload.pages) {
        state.pages = action.payload.pages;
      }
    },
    updateItem: (
      state: State,
      action: PayloadAction<BaseModelUpdatePayload<O>>
    ) => {
      state.data = state.data.map((value) =>
        value.id === action.payload.data.id ? action.payload.data : value
      );
    },
    deleteItem: (
      state: State,
      action: PayloadAction<BaseModelDeletePayload<O>>
    ) => {
      state.data = state.data.filter((value) => value.id !== action.payload.id);
    },
  };
}
