import { AuthUserObject } from "@/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import BaseApiService from "@/services/baseApi";
import TokenService from "@/services/token";
import { AppDispatch } from "@/store";
import AccountService from "@/services/account";

export interface AuthState {
  accessToken: string | null;
  refreshToken: string | null;
  rememberMe: boolean;
  user?: AuthUserObject;
}

function initialState(): AuthState {
  const credentials = TokenService.getJwtCredentials();

  return {
    ...(credentials
      ? {
          accessToken: credentials.access,
          refreshToken: credentials.refresh,
          rememberMe: credentials.remember,
        }
      : {
          accessToken: null,
          refreshToken: null,
          rememberMe: false,
        }),
    user: undefined,
  };
}

export interface JwtCredentialsPayload {
  accessToken: string;
  refreshToken: string;
}

export interface AuthLoginPayload extends JwtCredentialsPayload {
  remember_me: boolean;
}

export interface AuthRetrieveDataPayload {
  user: AuthUserObject;
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<AuthLoginPayload>) => {
      state.rememberMe = action.payload.remember_me;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;

      TokenService.setJwtCredentials(
        {
          access: action.payload.accessToken,
          refresh: action.payload.refreshToken,
        },
        action.payload.remember_me,
        false
      );

      BaseApiService.axios.interceptors.response.use(
        (response) => response,
        BaseApiService.refreshToken2
      );
    },
    retrieveAuthData: (
      state,
      action: PayloadAction<AuthRetrieveDataPayload>
    ) => {
      state.user = action.payload.user;
    },
    logout: (state) => {
      state.accessToken = null;
      state.refreshToken = null;
      state.user = undefined;
      TokenService.unsetJwtCredentials();
    },

    updateJwtCredentials: (
      state,
      action: PayloadAction<JwtCredentialsPayload>
    ) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
    },
  },
});

export const fetchAccountAction = () => async (dispatch: AppDispatch) => {
  try {
    const response = await AccountService.getAccount();
    dispatch(retrieveAuthDataAction({ user: response }));
  } catch (error) {
    throw error;
  }
};

export const {
  login: loginAction,
  retrieveAuthData: retrieveAuthDataAction,
  logout: logoutAction,
} = authSlice.actions;

export type AuthLoginAction = (payload: AuthLoginPayload) => void;
export type AuthRetrieveAuthDataAction = (
  payload: AuthRetrieveDataPayload
) => void;
export type AuthLogoutAction = () => void;

export default authSlice.reducer;
