import React, { useEffect } from "react";
import { MenuObject } from "@/types";
import { useTranslation } from "react-i18next";
import {
  Icon,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useAppSelector } from "@/hooks/appSelector";
import { useDispatch } from "react-redux";
import getFullRouteUrl from "@/utils/getFullRouteUrl";
import { Link } from "react-router-dom";
import { setMenuActiveItem } from "@/store/reducers/dashboard";

export type NavigationItemProps = {
  item: MenuObject;
  baseUrl?: string;
  autoTranslateLabels?: boolean;
  onClick?: (item: MenuObject) => void;
};

const NavigationItem: React.FC<NavigationItemProps> = ({
  item,
  baseUrl,
  autoTranslateLabels,
  onClick,
}) => {
  const { t: _t } = useTranslation();
  const dispatch = useDispatch();
  const { menuActiveItem: activeItem } = useAppSelector(
    (state) => state.dashboard
  );

  const t = autoTranslateLabels ? _t : (value: string) => value;

  const icon = item.icon ? (
    <ListItemIcon>
      <Icon component={item.icon} />
    </ListItemIcon>
  ) : undefined;

  let itemUrl = getFullRouteUrl(baseUrl ?? "/", item.url ?? "#");

  const itemHandler = (_item: MenuObject) => {
    onClick && onClick(_item);
    dispatch(setMenuActiveItem([_item.id]));
  };

  const isSelected = activeItem.findIndex((id) => id === item.id) > -1;

  useEffect(() => {
    let currentLocation = document.location.pathname;
    let currentItemUrl = itemUrl;

    if (!currentLocation.endsWith("/")) {
      currentLocation += "/";
    }

    if (currentLocation === currentItemUrl) {
      dispatch(setMenuActiveItem([item.id]));
    }
  }, [itemUrl, dispatch, item.id]);

  if (item.type === "link") {
    return (
      <ListItem disablePadding>
        <ListItemButton onClick={() => onClick && onClick(item)}>
          {icon}
          <ListItemText
            primary={
              autoTranslateLabels && item.label ? t(item.label) : item.label
            }
          />
        </ListItemButton>
      </ListItem>
    );
  }

  if (item.type === "dropdown") {
    return <></>;
  }

  return (
    <ListItem disablePadding>
      <ListItemButton
        component={Link}
        to={itemUrl}
        onClick={() => itemHandler(item)}
        selected={isSelected}>
        {icon}
        <ListItemText
          primary={
            autoTranslateLabels && item.label ? t(item.label) : item.label
          }
        />
      </ListItemButton>
    </ListItem>
  );
};

export default NavigationItem;
