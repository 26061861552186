import { RouteObject } from "react-router";
import DashboardLayout from "@/layouts/dashboard";
import HomePage from "@/pages/dashboard/home";
import applicationRoutes from "@/routes/dashboard/application";
import AuthenticationWrapper from "@/wrappers/authentication";
import accountRoutes from "@/routes/dashboard/account";

const dashboardRoutes: RouteObject = {
  path: "/dashboard/",
  element: (
    <AuthenticationWrapper>
      <DashboardLayout />
    </AuthenticationWrapper>
  ),
  children: [
    {
      path: "",
      element: <HomePage />,
    },
    {
      path: "application/",
      children: applicationRoutes,
    },
    {
      path: "account/",
      children: accountRoutes,
    },
  ],
};
export default dashboardRoutes;
