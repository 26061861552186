import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import commonEn from "@/locales/en/common.json";
import ptBr from "@/locales/pt/br.json";

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  lng: "en",
  defaultNS: "common",
  resources: {
    en: {
      common: commonEn,
    },
    pt: {
      br: ptBr,
    },
  },
});

export default i18n;
