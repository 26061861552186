import { JwtCredentials } from "@/types";
import GlobalService from "@/services/global";

const TOKEN_KEY = "acc_creds";

class Token {
  static instance: Token;

  constructor() {
    if (!Token.instance) {
      Token.instance = this;
    }
    return Token.instance;
  }

  setJwtCredentials = (
    credentials: JwtCredentials,
    remember: boolean,
    update_store = true
  ) => {
    if (update_store) {
      GlobalService.store.dispatch({
        type: "auth/updateJwtCredentials",
        payload: {
          accessToken: credentials.access,
          refreshToken: credentials.refresh,
        },
      });
    }

    if (remember) {
      localStorage.setItem(TOKEN_KEY, JSON.stringify(credentials));
    } else {
      sessionStorage.setItem(TOKEN_KEY, JSON.stringify(credentials));
    }
  };

  getJwtCredentials = () => {
    if (localStorage.getItem(TOKEN_KEY)) {
      return {
        ...JSON.parse(localStorage.getItem(TOKEN_KEY) || ""),
        remember: true,
      };
    } else if (sessionStorage.getItem(TOKEN_KEY)) {
      return {
        ...JSON.parse(sessionStorage.getItem(TOKEN_KEY) || ""),
        remember: false,
      };
    }
    return null;
  };

  unsetJwtCredentials = () => {
    localStorage.removeItem(TOKEN_KEY);
    sessionStorage.removeItem(TOKEN_KEY);
  };
}

const TokenService = new Token();
export default TokenService;
