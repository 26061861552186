import React from "react";
import { Fade, Grow, Slide, Zoom } from "@mui/material";

export type ScrollTriggerTransitionProps = {
  invert?: boolean;
  children: React.ReactElement<any, any>;
  transition?: "zoom" | "fade" | "grow" | "slide";
  target?: Node | Window;
  threshold?: number;
  disableHysteresis?: boolean;
};

function defaultTrigger(store: any, options: any = {}) {
  const {
    disableHysteresis = false,
    threshold = 100,
    target,
    invert = false,
  } = options;
  const previous = store.current;

  if (target) {
    if (invert) {
      if (target.pageYOffset !== undefined) {
        store.current = target.pageY - target.pageYOffset - target.clientHeight;
      } else {
        if (target.scrollTop === 0) {
          store.current = threshold + 1;
        } else {
          store.current =
            target.scrollHeight - target.scrollTop - target.clientHeight;
        }
      }
    } else {
      store.current =
        target.pageYOffset !== undefined
          ? target.pageYOffset
          : target.scrollTop;
    }
  }

  if (!disableHysteresis && previous !== undefined) {
    if (store.current < previous) {
      return false;
    }
  }

  return store.current > threshold;
}

const ScrollTriggerTransition: React.FC<ScrollTriggerTransitionProps> = ({
  invert,
  children,
  target,
  threshold = 100,
  transition,
  disableHysteresis,
}) => {
  const store = React.useRef<any>();
  const [trigger, setTrigger] = React.useState(() =>
    defaultTrigger(store, {
      threshold,
      disableHysteresis,
    })
  );
  let Component: React.ComponentType<any>;

  switch (transition) {
    case "grow":
      Component = Grow;
      break;
    case "fade":
      Component = Fade;
      break;
    case "slide":
      Component = Slide;
      break;
    case "zoom":
    default:
      Component = Zoom;
      break;
  }

  React.useEffect(() => {
    const handleScroll = () => {
      setTrigger(
        defaultTrigger(store, { target, threshold, disableHysteresis, invert })
      );
    };

    handleScroll();

    target?.addEventListener("scroll", handleScroll, { passive: true });
  }, [target, threshold, disableHysteresis, invert]);

  return <Component in={trigger}>{children}</Component>;
};

ScrollTriggerTransition.defaultProps = {
  transition: "zoom",
};

export default ScrollTriggerTransition;
