import React from "react";
import { useParams } from "react-router";
import { Params } from "@remix-run/router";

export type WithParamsProps<
  ParamsOrKey extends string | Record<string, string | undefined> = string,
> = Readonly<
  [ParamsOrKey] extends [string] ? Params<ParamsOrKey> : Partial<ParamsOrKey>
>;

export function withParams(WrappedComponent: React.ComponentType<any>) {
  return (props: any) => {
    const params = useParams();

    return <WrappedComponent {...props} {...params} />;
  };
}
