import React from "react";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";
import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import {
  algorithmsTypes,
  ApplicationFormData,
  clientTypes,
  grantTypes,
} from "@/types";

export type ApplicationFormProps = Omit<
  FormikProps<ApplicationFormData>,
  "handleSubmit"
> & {};

function ApplicationForm({
  errors,
  touched,
  values,
  handleChange,
  handleBlur,
}: ApplicationFormProps) {
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={12}>
        <TextField
          fullWidth
          id="name"
          name="name"
          value={values.name}
          error={Boolean(touched.name && errors.name)}
          helperText={touched && errors.name}
          onChange={handleChange}
          onBlur={handleBlur}
          variant="standard"
          label={t("application.fields.name")}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          id="redirect_uris"
          name="redirect_uris"
          value={values.redirect_uris}
          error={Boolean(touched.redirect_uris && errors.redirect_uris)}
          helperText={touched && errors.redirect_uris}
          onChange={handleChange}
          onBlur={handleBlur}
          variant="standard"
          label={t("application.fields.redirect_uris")}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth variant="standard">
          <InputLabel>
            {t("application.fields.authorization_grant_type")}
          </InputLabel>
          <Select
            id="authorization_grant_type"
            name="authorization_grant_type"
            value={values.authorization_grant_type}
            error={Boolean(
              touched.authorization_grant_type &&
                errors.authorization_grant_type
            )}
            onChange={handleChange}
            onBlur={handleBlur}>
            {grantTypes.map((value) => (
              <MenuItem key={value} value={value}>
                {t(`application.grantTypes.${value}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth variant="standard">
          <InputLabel>{t("application.fields.client_type")}</InputLabel>
          <Select
            id="client_type"
            name="client_type"
            value={values.client_type}
            error={Boolean(touched.client_type && errors.client_type)}
            onChange={handleChange}
            onBlur={handleBlur}>
            {clientTypes.map((value) => (
              <MenuItem key={value} value={value}>
                {t(`application.clientTypes.${value}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth variant="standard">
          <InputLabel shrink>{t("application.fields.algorithm")}</InputLabel>
          <Select
            id="algorithm"
            name="algorithm"
            displayEmpty
            value={values.algorithm}
            error={Boolean(touched.algorithm && errors.algorithm)}
            onChange={handleChange}
            onBlur={handleBlur}>
            {algorithmsTypes.map((value) => (
              <MenuItem key={value} value={value}>
                {t(
                  `application.algorithmsTypes.${
                    value === "" ? "NO_ALGORITHM" : value
                  }`
                )}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl>
          <FormControlLabel
            control={<Switch />}
            label={t("application.fields.skip_authorization")}
            id="skip_authorization"
            name="skip_authorization"
            checked={values.skip_authorization}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormControl>
      </Grid>
    </>
  );
}

export default ApplicationForm;
