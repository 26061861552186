import React from "react";
import Page from "@/components/page";
import PageHeader from "@/components/pageHeader";
import { connect } from "react-redux";
import { compose } from "@reduxjs/toolkit";
import { WithTranslation, withTranslation } from "react-i18next";
import PageContent from "@/components/pageContent";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { ApplicationFormData, ApplicationWithSecretObject } from "@/types";
import { fetchApplicationsAction } from "@/store/reducers/application";
import ApplicationService from "@/services/application";
import { enqueueSnackbar } from "notistack";
import NavigationService from "@/services/navigation";
import Form from "@/components/form";
import { Link } from "react-router-dom";
import { withConfirm, WithConfirmProps } from "@/hooks/confirm";
import { ConfirmOptions } from "material-ui-confirm";
import DataDisplay from "@/components/dataDisplay";
import { generatePath } from "react-router";
import ApplicationForm from "@/forms/application";

export type ApplicationCreatePageProps = WithTranslation &
  WithConfirmProps & {
    fetchApplications: typeof fetchApplicationsAction;
  };

class ApplicationCreatePage extends React.PureComponent<ApplicationCreatePageProps> {
  initialValues: ApplicationFormData = {
    algorithm: "",
    authorization_grant_type: "authorization-code",
    client_type: "confidential",
    name: "",
    redirect_uris: "",
    skip_authorization: false,
  };

  onSubmit = async (data: ApplicationFormData) => {
    const { fetchApplications, t, confirm } = this.props;
    const response = await ApplicationService.create(data);

    fetchApplications();

    enqueueSnackbar(t("application.create_page.success"), {
      variant: "success",
    });

    NavigationService.navigate(
      generatePath("/dashboard/application/:id/", {
        id: response.id,
      })
    );

    confirm(this.getConfirm(response));
  };

  getConfirm = (application: ApplicationWithSecretObject): ConfirmOptions => {
    const { t } = this.props;
    return {
      description: (
        <>
          <Typography component="p" variant={"body2"}>
            {t("application.create_page.confirm_read_secret")}
          </Typography>
          <DataDisplay mt={1}>{application.client_secret}</DataDisplay>
        </>
      ),
      allowClose: false,
      hideCancelButton: true,
    };
  };

  render() {
    const { t } = this.props;

    return (
      <Page>
        <PageHeader>{t("application.create_page.title")}</PageHeader>
        <PageContent>
          <Container maxWidth={"xl"}>
            <Form initialValues={this.initialValues} onSubmit={this.onSubmit}>
              {(formProps) => (
                <Grid container spacing={2}>
                  <ApplicationForm {...formProps} />
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}>
                      <Button component={Link} to="../">
                        {t("button.back")}
                      </Button>
                      <Button variant="contained" type="submit">
                        {t("button.create")}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Form>
          </Container>
        </PageContent>
      </Page>
    );
  }
}

const _mapStateToProps = () => ({});

const _mapDispatchToProps = {
  fetchApplications: fetchApplicationsAction,
};

const _connect = connect(_mapStateToProps, _mapDispatchToProps);

export default compose<any>(
  _connect,
  withTranslation(),
  withConfirm
)(ApplicationCreatePage);
