import { NavigateFunction } from "react-router";
import { To } from "react-router-dom";
import { NavigateOptions } from "react-router/dist/lib/context";

class Navigation {
  static instance: Navigation;
  private _navigate!: NavigateFunction;

  constructor() {
    if (!Navigation.instance) {
      Navigation.instance = this;
    }
    return Navigation.instance;
  }

  setNavigate(navigate: NavigateFunction) {
    this._navigate = navigate;
  }

  navigate(to: To, options?: NavigateOptions) {
    this._navigate(to, options);
  }
}

const NavigationService = new Navigation();

export default NavigationService;
