import { MenuGroupObject } from "@/types";
import { MdOutlineHome } from "react-icons/md";
import { AiOutlineApi } from "react-icons/ai";

const dashboardMenuConfig: MenuGroupObject = {
  id: "dashboard",
  label: "menu.general",
  baseUrl: "/dashboard",
  children: [
    {
      id: "home",
      type: "page",
      label: "menu.home",
      url: "/",
      icon: MdOutlineHome,
    },
    {
      id: "application",
      type: "page",
      label: "menu.application",
      url: "/application",
      icon: AiOutlineApi,
    },
  ],
};

export default dashboardMenuConfig;
