import { RouteObject } from "react-router";
import ApplicationPage from "@/pages/dashboard/application";
import ApplicationCreatePage from "@/pages/dashboard/application/create";
import ApplicationViewPage from "@/pages/dashboard/application/view";
import ApplicationEditPage from "@/pages/dashboard/application/edit";

const applicationRoutes: RouteObject[] = [
  {
    id: "application",
    path: "",
    element: <ApplicationPage />,
  },
  {
    id: "application-create",
    path: "create/",
    element: <ApplicationCreatePage />,
  },
  {
    id: "application-view",
    path: ":applicationId/",
    element: <ApplicationViewPage />,
  },
  {
    id: "application-edit",
    path: ":applicationId/edit/",
    element: <ApplicationEditPage />,
  },
];

export default applicationRoutes;
