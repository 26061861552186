import React from "react";
import { Outlet } from "react-router";
import { GlobalProps } from "@/types";
import { RootState } from "@/store";
import { AuthLogoutAction, logoutAction } from "@/store/reducers/auth";
import { connect } from "react-redux";
import { compose } from "@reduxjs/toolkit";
import { WithTranslation, withTranslation } from "react-i18next";
import {
  AppBar,
  Avatar,
  Box,
  ButtonBase,
  Collapse,
  Divider,
  Drawer,
  Icon,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { MdLogout, MdMenu, MdOutlinePerson } from "react-icons/md";
import Navigation from "@/components/navigation";
import menu from "@/menu";
import { withConfirm, WithConfirmProps } from "@/hooks/confirm";
import NavigationService from "@/services/navigation";
import { Link } from "react-router-dom";

export type DashboardLayoutProps = GlobalProps &
  WithTranslation &
  WithConfirmProps & {
    logout: AuthLogoutAction;
    toolbarTitle: string;
  };

type State = {
  mobileOpen: boolean;
  accountMenuOpen: boolean;
};

const drawerWidth = 240;

class DashboardLayout extends React.PureComponent<DashboardLayoutProps, State> {
  constructor(props: DashboardLayoutProps) {
    super(props);

    this.state = {
      mobileOpen: false,
      accountMenuOpen: false,
    };
  }

  handleDrawerToggle = () => {
    this.setState((prevState) => ({
      mobileOpen: !prevState.mobileOpen,
    }));
  };

  toggleAccountMenu = () => {
    this.setState((prevState) => ({
      accountMenuOpen: !prevState.accountMenuOpen,
    }));
  };

  handleLogout = () => {
    const { logout, confirm } = this.props;

    confirm({
      title: "Logout",
      description: "Are you sure you want to logout?",
    }).then(() => {
      logout();
      NavigationService.navigate("/");
    });
  };

  renderDrawerContent = () => {
    const { loggedUser, t } = this.props;
    const { accountMenuOpen } = this.state;
    return (
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
        }}>
        <Box>
          <Toolbar>
            <Typography variant="h6" noWrap>
              {t("app_name")}
            </Typography>
          </Toolbar>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            overflow: "auto",
          }}>
          <Navigation
            items={menu.items}
            // onClickItem={this.handleDrawerToggle}
          />
        </Box>
        <Box
          sx={{
            mb: 1.2,
          }}>
          <ButtonBase
            onClick={this.toggleAccountMenu}
            sx={{
              width: "100%",
            }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                py: 1.1,
                px: 2,
                gap: 1,
              }}>
              <Avatar />
              <Stack
                spacing={-0.6}
                sx={{
                  textAlign: "left",
                }}>
                <Typography variant="subtitle1" noWrap>
                  {loggedUser?.first_name} {loggedUser?.last_name}
                </Typography>
                <Typography variant="caption" noWrap>
                  {loggedUser?.email}
                </Typography>
              </Stack>
            </Box>
          </ButtonBase>
          <Collapse in={accountMenuOpen}>
            <List dense>
              <ListItemButton
                component={Link}
                to={"account/"}
                // onClick={this.handleDrawerToggle}
                sx={{
                  pl: 4,
                }}>
                <ListItemIcon>
                  <Icon>
                    <MdOutlinePerson />
                  </Icon>
                </ListItemIcon>
                <ListItemText primary={t("commons.account")} />
              </ListItemButton>
            </List>

            <Divider />
            <List
              dense
              sx={{
                pb: 0,
              }}>
              <ListItemButton
                onClick={this.handleLogout}
                sx={{
                  pl: 4,
                }}>
                <ListItemIcon>
                  <Icon>
                    <MdLogout />
                  </Icon>
                </ListItemIcon>
                <ListItemText primary={t("commons.logout")} />
              </ListItemButton>
            </List>
          </Collapse>
        </Box>
      </Box>
    );
  };

  render() {
    const { toolbarTitle } = this.props;
    const { mobileOpen } = this.state;

    const container =
      window !== undefined ? () => window.document.body : undefined;

    const drawerContent = this.renderDrawerContent();

    return (
      <Box
        sx={{
          display: "flex",
        }}>
        <AppBar
          position="fixed"
          sx={{
            display: {
              md: "none",
            },
          }}>
          <Toolbar>
            <IconButton
              color="inherit"
              edge="start"
              onClick={this.handleDrawerToggle}
              sx={{
                mr: 2,
              }}>
              <MdMenu />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              {toolbarTitle}
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{
            width: {
              md: drawerWidth,
            },
          }}>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={this.handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}>
            {drawerContent}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              "display": { xs: "none", md: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open>
            {drawerContent}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            width: {
              sm: "100%",
              md: `calc(100% - ${drawerWidth}px)`,
            },
            display: "flex",
            flexDirection: "column",
          }}>
          <Toolbar
            sx={{
              display: {
                md: "none",
              },
            }}
          />
          <Outlet />
        </Box>
      </Box>
    );
  }
}

const _mapStateToProps = (state: RootState) => ({
  loggedUser: state.auth.user,
  toolbarTitle: state.dashboard.toolbarTitle,
});

const _mapActionsToProps = {
  logout: logoutAction,
};

const _connect = connect(_mapStateToProps, _mapActionsToProps);

export default compose<any>(
  _connect,
  withTranslation(),
  withConfirm
)(DashboardLayout);
