import React from "react";

const Page404: React.FC = () => {
  return (
    <div className="page404">
      <h1>404</h1>
      <h2>Page not found</h2>
    </div>
  );
};

export default Page404;
