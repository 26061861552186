import BaseApiService from "@/services/baseApi";
import {
  ApiUrlParams,
  ApplicationFormData,
  ApplicationObject,
  ApplicationWithSecretObject,
} from "@/types";
import { RawAxiosRequestConfig } from "axios";
import _ from "lodash";

class Application {
  static instance: Application;
  private readonly api!: typeof BaseApiService;

  constructor() {
    if (!Application.instance) {
      this.api = BaseApiService;
      Application.instance = this;
    }

    return Application.instance;
  }

  public list = async (
    page?: number,
    params?: ApiUrlParams,
    config?: RawAxiosRequestConfig
  ) => {
    return this.api.listPaginated<ApplicationObject>(
      "/application/",
      page,
      params,
      config
    );
  };

  create = async (
    data: ApplicationFormData,
    params?: ApiUrlParams,
    config?: RawAxiosRequestConfig
  ) => {
    return this.api.create<ApplicationWithSecretObject, ApplicationFormData>(
      "/application/",
      data,
      true,
      params,
      config
    );
  };

  update = async (
    id: string,
    data: ApplicationFormData,
    params?: ApiUrlParams,
    config?: RawAxiosRequestConfig
  ) => {
    const _params = _.merge(params, { id });

    return this.api.update<ApplicationObject, ApplicationFormData>(
      "/application/:id/",
      data,
      true,
      _params,
      config
    );
  };

  retrieve = async (
    id: string,
    params?: ApiUrlParams,
    config?: RawAxiosRequestConfig
  ) => {
    return this.api.retrieve<ApplicationObject>(
      "/application/:id/",
      id,
      params,
      config
    );
  };

  delete = async (
    id: string,
    params?: ApiUrlParams,
    config?: RawAxiosRequestConfig
  ) => {
    const _params = _.merge(params, { id });

    return this.api.delete<ApplicationObject>(
      "/application/:id/",
      _params,
      config
    );
  };
}

const ApplicationService = new Application();
export default ApplicationService;
