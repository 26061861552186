import {
  ApiUrlParams,
  ApplicationObject,
  BaseModelAddItemsAction,
  BaseModelDeleteItemAction,
  BaseModelUpdateItemAction,
} from "@/types";
import { createSlice } from "@reduxjs/toolkit";
import { baseModelsReducers } from "@/store/baseReducers";
import ApplicationService from "@/services/application";
import { RawAxiosRequestConfig } from "axios";

export interface ApplicationState {
  data: ApplicationObject[];
  pages: number;
  currentPage: number;
}

const initialState: ApplicationState = {
  data: [],
  pages: 1,
  currentPage: 1,
};

const baseReducers = baseModelsReducers<ApplicationObject, ApplicationState>();

export const ApplicationSlice = createSlice({
  name: "Application",
  initialState,
  reducers: {
    ...baseReducers,
  },
});

export const {
  addItems: addApplicationAction,
  updateItem: updateApplicationAction,
  deleteItem: deleteApplicationAction,
} = ApplicationSlice.actions;

export const fetchApplicationsAction =
  (
    page: number = 1,
    params?: ApiUrlParams,
    config?: RawAxiosRequestConfig
  ): any =>
  async (dispatch: any) => {
    try {
      const response = await ApplicationService.list(page, params, config);
      dispatch(
        addApplicationAction({
          data: response.results,
          pages: response.pages,
          currentPage: page,
        })
      );
    } catch (error) {
      throw error;
    }
  };

export type ApplicationAddItemsAction =
  BaseModelAddItemsAction<ApplicationObject>;

export type ApplicationUpdateItemAction =
  BaseModelUpdateItemAction<ApplicationObject>;

export type ApplicationDeleteItemAction =
  BaseModelDeleteItemAction<ApplicationObject>;

export default ApplicationSlice.reducer;
