import React from "react";
import { Box, CircularProgress } from "@mui/material";
import CircularProgressWithLabel from "@/components/circularProgressWithLabel";

export type LoadingProps = {
  percent?: number;
};

const Loading: React.FC<LoadingProps> = ({ percent }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
      {percent ? (
        <CircularProgressWithLabel value={percent || 0} />
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
};

export default Loading;
