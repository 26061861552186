import React from "react";
import { OAuth2AuthorizeData } from "@/types";
import OAuth2Service from "@/services/oauth2";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import Loading from "@/components/loading";
import withLocation, { WithLocationProps } from "@/hooks/location";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "@reduxjs/toolkit";

export type AuthorizePageProps = WithTranslation & WithLocationProps & {};

type State = {
  authorizeData: OAuth2AuthorizeData | null;
  loading: boolean;
  error: string | null;
};

class AuthorizePage extends React.PureComponent<AuthorizePageProps, State> {
  constructor(props: AuthorizePageProps) {
    super(props);

    this.state = {
      authorizeData: null,
      loading: true,
      error: null,
    };
  }

  componentDidMount() {
    this.fetchAuthorizeData();
  }

  fetchAuthorizeData = () => {
    const {
      location: { search },
      t,
    } = this.props;
    const params = new URLSearchParams(search);

    this.setState({ loading: true, error: null }, async () => {
      try {
        const authorizeData = await OAuth2Service.getAuthorizeData(params);

        if (authorizeData.redirect) {
          window.location.href = authorizeData.redirect;
          return;
        }

        this.setState({ authorizeData, loading: false });
      } catch (e) {
        this.setState({
          error: t("oauth.errors.autorize_fetch"),
          loading: false,
        });
      }
    });
  };

  handleAuthorize = async () => {
    const { authorizeData } = this.state;

    if (!authorizeData) {
      return;
    }

    try {
      const response = await OAuth2Service.authorize({
        allow: true,
        scope: authorizeData?.scopes.join(" "),
        ...authorizeData,
      });

      if ("redirect" in response) {
        window.location.href = response.redirect;
      }
    } catch (e: any) {
      if ("redirect" in e.data) {
        window.location.href = e.data.redirect;
      }
    }
  };

  render() {
    const { authorizeData, loading, error } = this.state;
    const { t } = this.props;

    return (
      <Container maxWidth="md">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
          <Stack spacing={2} sx={{ mt: 8, mb: 4 }}>
            <Box>
              <Typography variant="h5" textAlign="center">
                lbdev odin - Authorization
              </Typography>
            </Box>
            {loading && <Loading />}
            {error && <Typography color="error">{error}</Typography>}
            {!loading && !error && authorizeData && (
              <Card>
                <CardContent>
                  <Typography variant="h6" textAlign="center" gutterBottom>
                    {t("oauth.application_request.title", {
                      name: authorizeData.application_name,
                    })}
                  </Typography>
                  <Typography gutterBottom>
                    {t("oauth.application_request.description", {
                      name: authorizeData.application_name,
                    })}
                  </Typography>
                  <Typography component="p" fontSize={"1.1rem"}>
                    {t("oauth.application_request.scopes")}
                  </Typography>
                  <ul>
                    {authorizeData.scopes.map((scope) => (
                      <li key={scope}>
                        <strong>{t(`oauth.scopes.${scope}`)}</strong>:{" "}
                        {t(`oauth.scopes.${scope}_description`)}
                      </li>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button onClick={this.handleAuthorize}>
                    {t("oauth.application_request.allow")}
                  </Button>
                </CardActions>
              </Card>
            )}
          </Stack>
        </Box>
      </Container>
    );
  }
}

const _mapStateToProps = () => ({});

const _mapActionsToProps = {};

const _connect = connect(_mapStateToProps, _mapActionsToProps);

export default compose<any>(
  _connect,
  withTranslation(),
  withLocation
)(AuthorizePage);
