import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type DashboardState = {
  toolbarTitle?: string;
  menuActiveItem: string[];
};

const initialState: DashboardState = {
  toolbarTitle: "",
  menuActiveItem: ["home"],
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setToolbarTitle: (state, action: PayloadAction<string | undefined>) => {
      state.toolbarTitle = action.payload;
    },
    setMenuActiveItem: (state, action: PayloadAction<string[]>) => {
      state.menuActiveItem = action.payload;
    },
  },
});

export const { setToolbarTitle, setMenuActiveItem } = dashboardSlice.actions;

export default dashboardSlice.reducer;
